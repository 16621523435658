import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';

const FooterLogo = () => (
  <Box
    width={{ xs: '100%', lg: '50%' }}
    display="flex"
    flexDirection={{ xs: 'column', lg: 'row' }}
  >
    <Box
      paddingTop={{ xs: '0', lg: '6x' }}
      marginRight={{ xs: '0', lg: '6x' }}
    >
      <NuLogo variant="white" />
    </Box>
    <Typography
      intlKey="FOOTER.COPYRIGHT.TEXT"
      variant="paragraph1"
      color="white.default"
      marginTop="4x"
      whiteSpace="pre-line"
      strong
    />
  </Box>
);

export default FooterLogo;
