import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  gap: 16px;

  li :hover {
    color: #820AD1;
  }

  svg {
    margin: 0;
  }
`;

function MenuSocialMediaIcons() {
  return (
    <StyledBox
      tag="ul"
      display="flex"
      justifyContent="flex-end"
    >
      <li>
        <Link
          href="https://www.facebook.com/nu.mx"
          iconProps={{ name: 'facebook', title: 'Facebook' }}
          color="black"
        />
      </li>
      <li>
        <Link
          href="https://www.instagram.com/nu.mx"
          iconProps={{ name: 'instagram', title: 'Instagram' }}
          color="black"
        />
      </li>
      <li>
        <Link
          href="https://www.linkedin.com/company/nu-mexico"
          iconProps={{ name: 'linkedin', title: 'Linkedin' }}
          color="black"
        />
      </li>
      <li>
        <Link
          href="https://twitter.com/soynumx"
          iconProps={{ name: 'twitter', title: 'Twitter' }}
          color="black"
        />
      </li>
      <li>
        <Link
          href="https://youtube.com/channel/UC-Y2_nbByqsViBubK3QfHDw"
          iconProps={{ name: 'youtube', title: 'youtube' }}
          color="black"
        />
      </li>
      <li>
        <Link
          href="https://www.tiktok.com/@nu.mx"
          iconProps={{ name: 'tiktok', title: 'tiktok' }}
          color="black"
        />
      </li>
    </StyledBox>
  );
}

export default MenuSocialMediaIcons;
