import React from 'react';
import PropTypes from 'prop-types';

import HeaderWrapper from './HeaderWrapper';
import { HeaderContainer } from './HeaderContainer';

function HeaderXP({
  isHidden,
  children,
}) {
  return (
    <HeaderWrapper>
      <HeaderContainer isHidden={isHidden}>
        {children}
      </HeaderContainer>
    </HeaderWrapper>
  );
}

HeaderXP.defaultProps = {
  children: undefined,
  isHidden: false,
};

HeaderXP.propTypes = {
  children: PropTypes.node,
  isHidden: PropTypes.bool,
};

export default HeaderXP;
