import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';

import FooterNavigation from './FooterNavigation';
import FooterSocial from './FooterSocial';
import FooterInfo from './FooterInfo';
import FooterLogo from './FooterLogo';

const Footer = ({ hideFooterNavigation }) => (
  <Box
    tag="footer"
    padding={{ xs: '6x', lg: '12x' }}
    backgroundColor="black.default"
    color="white.default"
  >
    {!hideFooterNavigation && <FooterNavigation />}
    <Box
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
      alignItems={{ xs: 'flex-start', lg: 'center' }}
      justifyContent="space-between"
    >
      <FooterLogo />
      <FooterSocial />
    </Box>
    <FooterInfo />
  </Box>
);

Footer.defaultProps = {
  hideFooterNavigation: false,
};

Footer.propTypes = {
  hideFooterNavigation: PropTypes.bool,
};

export default Footer;
