import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  white-space: normal;

  @media (width >= 1024px) {
    white-space: pre-line;
  }
`;

const FooterInfo = () => (
  <Box
    marginTop="12x"
    marginBottom={{ xs: '12x', lg: '0' }}
    display="flex"
    flexDirection={{ xs: 'column', lg: 'row' }}
    justifyContent="space-between"
  >
    <Typography
      intlKey="FOOTER.INFO.ADDRESS"
      variant="paragraph1"
      color="white.default"
      marginBottom="6x"
      width={{ xs: '100%', lg: '60%' }}
      whiteSpace="pre-line"
    />

    <StyledLink
      color="white"
      typographyProps={{ variant: 'paragraph2', strong: true, paddingBottom: '2x' }}
      href="https://cdn.nubank.com.br/MX/costos-y-comisiones.pdf"
    >
      {'Consulta los Costos y  Comisiones \n de nuestros productos'}
    </StyledLink>

    <Link
      color="white"
      typographyProps={{ variant: 'paragraph2', strong: true, paddingBottom: '2x' }}
      href="/contratos"
    >
      Contratos y formatos legales
    </Link>
  </Box>
);

export default FooterInfo;
