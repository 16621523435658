import React from 'react';
import { useIntl } from 'react-intl';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import StyledCollapse from './styles/StyledCollapse';

const FooterNavigation = () => {
  const { formatMessage } = useIntl();

  return (
    <Box marginBottom={{ xs: '6x', lg: '12x' }}>
      <Grid
        gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr 1fr 1fr' }}
        paddingLeft="0"
        paddingRight="0"
      >
        <Grid.Child>
          <StyledCollapse id="contact" initiallyOpen>
            <Collapse.Header>
              <Typography
                intlKey="FOOTER.CONTACT.TITLE"
                color="white.default"
                variant="subtitle1"
                strong
              />
            </Collapse.Header>

            <Collapse.Body>
              <Box
                display="flex"
                flexDirection="column"
                marginTop="4x"
              >
                <Typography
                  intlKey="FOOTER.CONTACT.CHAT.LABEL"
                  variant="paragraph1"
                  paddingBottom="2x"
                  strong
                  color="white.default"
                />
                <Link
                  color="white"
                  intlKey="FOOTER.CONTACT.SUPPORT_EMAIL.LABEL"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  paddingBottom="2x"
                  href={`mailto:${formatMessage({ id: 'FOOTER.CONTACT.SUPPORT_EMAIL.LABEL' })}`}
                />
                <Link
                  color="white"
                  intlKey="FOOTER.CONTACT.PHONE.LABEL"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  paddingBottom="2x"
                  href={`tel:${formatMessage({ id: 'FOOTER.CONTACT.PHONE.LABEL' })}`}
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.CONTACT.FAQ.LINK' })}
                  intlKey="FOOTER.CONTACT.FAQ.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
                  href={formatMessage({ id: 'FOOTER.CONTACT.UNE.LINK' })}
                  intlKey="FOOTER.CONTACT.UNE.LABEL"
                  marginBottom="8x"
                />
              </Box>

            </Collapse.Body>
          </StyledCollapse>
        </Grid.Child>
        <Grid.Child>
          <StyledCollapse id="transparency" initiallyOpen>
            <Collapse.Header>
              <Typography
                intlKey="FOOTER.TRANSPARENCY.TITLE"
                color="white.default"
                variant="subtitle1"
                strong

              />
            </Collapse.Header>

            <Collapse.Body>
              <Box
                display="flex"
                flexDirection="column"
                marginTop="4x"
              >
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.TRANSPARENCY.SAVINGS_PROTECTION_FUND.LINK' })}
                  intlKey="FOOTER.TRANSPARENCY.SAVINGS_PROTECTION_FUND"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION.LINK' })}
                  intlKey="FOOTER.TRANSPARENCY.FINANCIAL_INFORMATION"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.TRANSPARENCY.REMUNERATION_SYSTEM.LINK' })}
                  intlKey="FOOTER.TRANSPARENCY.REMUNERATION_SYSTEM"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.TRANSPARENCY.QUERIES_AND_CLARIFICATIONS_SPEI.LINK' })}
                  intlKey="FOOTER.TRANSPARENCY.QUERIES_AND_CLARIFICATIONS_SPEI"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.TRANSPARENCY.COLLECTION.PATH' })}
                  intlKey="FOOTER.TRANSPARENCY.COLLECTION.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.TRANSPARENCY.NU_IMPACTO.PATH' })}
                  intlKey="FOOTER.TRANSPARENCY.NU_IMPACTO.LABEL"
                  variant="action"
                  width="fit-content"

                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
                  href={formatMessage({ id: 'FOOTER.TRANSPARENCY.INVESTORS.PATH' })}
                  intlKey="FOOTER.TRANSPARENCY.INVESTORS.LABEL"
                  variant="action"
                  width="fit-content"
                />

                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  target="_blank"
                  href={formatMessage({ id: 'FOOTER.NAVIGATE.ETHICS.PATH' })}
                  intlKey="FOOTER.NAVIGATE.ETHICS.LABEL"
                />

              </Box>

            </Collapse.Body>
          </StyledCollapse>
        </Grid.Child>
        <Grid.Child>
          <StyledCollapse id="products" initiallyOpen>
            <Collapse.Header>
              <Typography
                intlKey="FOOTER.PRODUCTS.TITLE"
                color="white.default"
                variant="subtitle1"
                strong
              />
            </Collapse.Header>

            <Collapse.Body>
              <Box
                display="flex"
                flexDirection="column"
                marginTop="4x"
              >
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.PRODUCTS.DEBIT_CARD.LINK' })}
                  intlKey="FOOTER.PRODUCTS.DEBIT_CARD"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
                  href={formatMessage({ id: 'FOOTER.PRODUCTS.CREDIT_CARD.LINK' })}
                  intlKey="FOOTER.PRODUCTS.CREDIT_CARD"
                />
              </Box>
            </Collapse.Body>
          </StyledCollapse>
        </Grid.Child>
        <Grid.Child>
          <StyledCollapse id="navigate" initiallyOpen>
            <Collapse.Header>
              <Typography
                intlKey="FOOTER.NAVIGATE.TITLE"
                color="white.default"
                variant="subtitle1"
                strong
              />
            </Collapse.Header>

            <Collapse.Body>
              <Box
                display="flex"
                flexDirection="column"
                marginTop="4x"
              >
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.NAVIGATE.HOME.PATH' })}
                  intlKey="FOOTER.NAVIGATE.HOME.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.NAVIGATE.ABOUT_US.PATH' })}
                  intlKey="FOOTER.NAVIGATE.ABOUT_US.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.NAVIGATE.PRESS.PATH' })}
                  intlKey="FOOTER.NAVIGATE.PRESS.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.NAVIGATE.BLOG.PATH' })}
                  intlKey="FOOTER.NAVIGATE.BLOG.LABEL"
                  variant="action"
                  width="fit-content"

                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.NAVIGATE.CAREERS.PATH' })}
                  intlKey="FOOTER.NAVIGATE.CAREERS.LABEL"
                  variant="action"
                  width="fit-content"

                />

                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  target="_blank"
                  href={formatMessage({ id: 'FOOTER.NAVIGATE.TERMS.PATH' })}
                  intlKey="FOOTER.NAVIGATE.TERMS.LABEL"
                />

              </Box>
            </Collapse.Body>
          </StyledCollapse>
        </Grid.Child>
        <Grid.Child>
          <StyledCollapse id="privacy" initiallyOpen>
            <Collapse.Header>
              <Typography
                intlKey="FOOTER.PRIVACY_POLICY.TITLE"
                color="white.default"
                variant="subtitle1"
                strong
              />
            </Collapse.Header>

            <Collapse.Body>
              <Box
                display="flex"
                flexDirection="column"
                marginTop="4x"
              >
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.PRIVACY_POLICY.CANDIDATES.PATH' })}
                  target="_blank"
                  intlKey="FOOTER.PRIVACY_POLICY.CANDIDATES.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.PRIVACY_POLICY.CLIENTS_AND_PROSPECTS.PATH' })}
                  target="_blank"
                  intlKey="FOOTER.PRIVACY_POLICY.CLIENTS_AND_PROSPECTS.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.PRIVACY_POLICY.ASSISTANCE.PATH' })}
                  target="_blank"
                  intlKey="FOOTER.PRIVACY_POLICY.ASSISTANCE.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.PRIVACY_POLICY.PROVIDERS.PATH' })}
                  target="_blank"
                  intlKey="FOOTER.PRIVACY_POLICY.PROVIDERS.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
                  href="/aviso-de-privacidad-redes-sociales"
                  target="_blank"
                >
                  Privacidad en Redes Sociales
                </Link>
              </Box>
            </Collapse.Body>
          </StyledCollapse>
        </Grid.Child>
        <Grid.Child>
          <StyledCollapse id="regulation" initiallyOpen>
            <Collapse.Header>
              <Typography
                intlKey="FOOTER.REGULATION.TITLE"
                color="white.default"
                variant="subtitle1"
                strong
              />
            </Collapse.Header>

            <Collapse.Body>
              <Box
                display="flex"
                flexDirection="column"
                marginTop="4x"
              >
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.REGULATION.BUREAU_ENTITIES.PATH' })}
                  intlKey="FOOTER.REGULATION.BUREAU_ENTITIES.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.REGULATION.CONDUSEF.PATH' })}
                  intlKey="FOOTER.REGULATION.CONDUSEF.LABEL"
                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, marginBottom: '8x' }}
                  href={formatMessage({ id: 'FOOTER.REGULATION.CNBV.PATH' })}
                  intlKey="FOOTER.REGULATION.CNBV.LABEL"
                  variant="action"
                  width="fit-content"

                />
              </Box>
            </Collapse.Body>
          </StyledCollapse>
        </Grid.Child>
        <Grid.Child>
          <StyledCollapse id="world" initiallyOpen>
            <Collapse.Header>
              <Typography
                intlKey="FOOTER.WORLD.TITLE"
                color="white.default"
                variant="subtitle1"
                strong
              />
            </Collapse.Header>

            <Collapse.Body>
              <Box
                display="flex"
                flexDirection="column"
                marginTop="4x"
              >
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.WORLD.BRAZIL.PATH' })}
                  intlKey="FOOTER.WORLD.BRAZIL"
                  variant="action"
                  width="fit-content"

                />
                <Link
                  color="white"
                  typographyProps={{ variant: 'paragraph1', strong: true, paddingBottom: '2x' }}
                  href={formatMessage({ id: 'FOOTER.WORLD.COLOMBIA.PATH' })}
                  intlKey="FOOTER.WORLD.COLOMBIA"
                  variant="action"
                  width="fit-content"

                />
              </Box>
            </Collapse.Body>
          </StyledCollapse>
        </Grid.Child>
      </Grid>

    </Box>
  );
};

export default FooterNavigation;
