import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import LockScroll from '@nubank/nuds-web/components/LockScroll/LockScroll';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

import { typeValidation, rendererComponents } from '../../../utils';
import Section from '../Section';
import SectionTitle from '../SectionTitle';
import SectionLink from '../SectionLink';
import Column from '../Column';
import ColumnTitle from '../ColumnTitle';
import ColumnLink from '../ColumnLink';

import CollapseWrapper from './components/CollapseWrapper';
import NavigationListSmallScreenWrapper from './styles/NavigationListSmallScreenWrapper';

const KeysToComponentTitleMap = {
  // eslint-disable-next-line react/prop-types
  Section: ({ idNav, children }) => <Fragment key={`small-screen--section-${idNav}`}>{children}</Fragment>,
  SectionTitle,
  SectionLink,
};

function ColumnLinkCustom({ afterComponent, beforeComponent, ...props }) {
  const AfterComponent = afterComponent;
  const BeforeComponent = beforeComponent;

  return (
    <Box display="flex" alignItems="baseline">
      {beforeComponent && <BeforeComponent />}
      <ColumnLink {...props} />
      {afterComponent && <AfterComponent />}
    </Box>
  );
}

ColumnLinkCustom.defaultProps = {
  afterComponent: undefined,
  beforeComponent: undefined,
};

ColumnLinkCustom.propTypes = {
  afterComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  beforeComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

const KeysToComponentBodyMap = {
  Section,
  Column,
  ColumnTitle,
  ColumnLink: ColumnLinkCustom,
};

// eslint-disable-next-line react/prop-types
const ListItemMenu = ({ children }) => (
  <Box
    tag="li"
    borderBottom={`1px solid ${nuDSColor('black', 'defaultT10')()}`}
    paddingVertical={{
      xs: '4x',
      md: '6x',
    }}
    paddingHorizontal="6x"
  >
    {children}
  </Box>
);

const rendererComponentsCollapseList = componentProps => {
  const Header = rendererComponents(KeysToComponentTitleMap, componentProps) || '';
  const Body = rendererComponents(KeysToComponentBodyMap, componentProps) || '';

  return (
    <ListItemMenu key={`list-item-menu-${componentProps.idNav}`}>
      <CollapseWrapper id={componentProps.idNav}>
        <CollapseWrapper.Header
          iconToClose="chevron-up"
          iconToOpen="chevron-down"
          iconSize="default"
        >
          {Header}
        </CollapseWrapper.Header>
        <CollapseWrapper.Body>
          {Body}
        </CollapseWrapper.Body>
      </CollapseWrapper>
    </ListItemMenu>
  );
};

const NavigationListSmallScreen = ({
  listNavigationComponents,
  actionsSmallScreenChildren,
  isHidden,
  lockScroll,
}) => (
  <NavigationListSmallScreenWrapper $isHidden={isHidden} tag="nav">
    <Box
      tag="ul"
      role="menubar"
    >
      {listNavigationComponents.map(component => (
        <Fragment key={`small-screen-list-item--${component.props.idNav}`}>
          {component.props.children.length === 1
            ? (
              <ListItemMenu>
                {rendererComponents(KeysToComponentTitleMap, component.props)}
              </ListItemMenu>
            )
            : rendererComponentsCollapseList(component.props)}
        </Fragment>
      ))}
    </Box>
    <Box
      width="auto"
      padding={{
        xs: '6x',
        md: '8x',
      }}
    >
      {actionsSmallScreenChildren}
    </Box>
    <LockScroll lockScroll={lockScroll} open={!isHidden} />
  </NavigationListSmallScreenWrapper>
);

NavigationListSmallScreen.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('NavigationListSmallScreen'),
  actionsSmallScreenChildren: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),
  isHidden: PropTypes.bool.isRequired,
  listNavigationComponents: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        __TYPE: PropTypes.string,
        children: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
          PropTypes.array,
        ]).isRequired,
        name: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  /** A property to determine if the scroll of the page will be locked when Drawer is open */
  lockScroll: PropTypes.bool,
};

NavigationListSmallScreen.defaultProps = {
  __TYPE: 'NavigationListSmallScreen',
  actionsSmallScreenChildren: undefined,
  lockScroll: true,
};

export default NavigationListSmallScreen;
