import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';

const FooterSocial = () => {
  const { formatMessage } = useIntl();

  return (
    <Box marginTop="12x">
      <Link
        href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK' })}
        color="white"
        iconProps={{ name: 'instagram' }}
        paddingRight="4x"
      />
      <Link
        href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK' })}
        color="white"
        iconProps={{ name: 'facebook' }}
        paddingRight="4x"
      />
      <Link
        href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK' })}
        color="white"
        iconProps={{ name: 'linkedin' }}
        paddingRight="4x"
      />
      <Link
        href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.YOUTUBE.LINK' })}
        color="white"
        iconProps={{ name: 'youtube' }}
        paddingRight="4x"
      />
      <Link
        href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.TWITTER.LINK' })}
        color="white"
        iconProps={{ name: 'twitter' }}
        paddingRight="4x"
      />
      <Link
        href={formatMessage({ id: 'FOOTER.SOCIAL_MEDIA.TIKTOK.LINK' })}
        color="white"
        iconProps={{ name: 'tiktok' }}
        paddingRight="4x"
      />
    </Box>
  );
};

export default FooterSocial;
